import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { Typography } from '../../Typography';

interface SliderValue {
  value: string;
  label: string;
}

interface SliderProps {
  title: string;
  options: [SliderValue, SliderValue, SliderValue?];
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const THUMB_SIZE = 24;
const TRACK_THICKNESS = 4;
const TRACK_PADDING = 12;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const SliderContainer = styled(Box)`
  display: flex;
  position: relative;
  width: 100%;
`;

const Track = styled(Box)`
  position: absolute;
  height: ${TRACK_THICKNESS}px;
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: 9999px;
  left: ${TRACK_PADDING}px;
  right: ${TRACK_PADDING}px;
  top: 22px;
`;

const SliderItem = styled('button')<{
  isFirst?: boolean;
  isLast?: boolean;
  isSelected?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${(props) => (props.isFirst ? 'flex-start' : props.isLast ? 'flex-end' : 'center')};
  background: none;
  border: none;
  cursor: pointer;
  padding: 12px 0 0 0;

  &:focus {
    outline: none;
  }
`;

const Thumb = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>`
  width: ${THUMB_SIZE}px;
  height: ${THUMB_SIZE}px;
  background-color: ${(props) => (props.isSelected ? '#00FF9D' : '#2F2F2F')};
  border-radius: 9999px;
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSelected &&
    `
    box-shadow: 0 0 30px #00FF9D;
  `}
  z-index: 10000;
`;

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>`
  color: rgb(255, 252, 252);
  opacity: ${(props) => (props.isSelected ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export function AgentSlider({
  title,
  options,
  defaultValue,
  value: valueProp,
  onChange,
}: Readonly<SliderProps>) {
  const [valueState, setValueState] = useState(defaultValue);
  const value = valueProp ?? valueState;

  const handlePress = (option: SliderValue) => {
    if (valueProp === undefined) {
      setValueState(option.value);
    }
    if (value !== option.value && onChange) {
      onChange(option.value);
    }
  };

  return (
    <Container>
      {title && (
        <Typography
          variant="body.bold-condensed"
          data-testid="priority-card__subtitle"
          align="center"
        >
          {title}
        </Typography>
      )}
      <SliderContainer>
        <Track />
        {options.map(
          (option, index) =>
            option && (
              <SliderItem
                key={option.value}
                isSelected={option.value === value}
                isFirst={index === 0}
                isLast={index === options.length - 1}
                onClick={() => handlePress(option)}
              >
                <Thumb isSelected={option.value === value} />
                <Label variant="body.regular-condensed" isSelected={option.value === value}>
                  {option.label}
                </Label>
              </SliderItem>
            ),
        )}
      </SliderContainer>
    </Container>
  );
}
